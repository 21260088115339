<template>
  <b-img
    :src="$store.state.app.logoSRC"
    :alt="$t($store.state.app.title)"
  />
</template>

<script>
import { BImg } from 'bootstrap-vue'

export default {
  name: 'LogoImg',
  components: {
    BImg,
  },
}
</script>

<style scoped>

</style>
